<template>
  <v-container fill-height class="fill-height" fluid>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <auth-card @edit="dialog = !dialog" />
      </v-col>
    </v-row>
    <add-user-dialog
      :dialog="dialog"
      :currentUser="currentUser"
      @close="dialog = false"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AddUserDialog from "../components/AddUserDialog.vue";
import AuthCard from "../components/AuthCard.vue";
export default {
  name: "Profile",
  components: { AuthCard, AddUserDialog },
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  methods: {
    consoleLog() {
      console.log("consoleLog");
    },
  },
};
</script>